<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                <div class="card-body">
                    <!-- <h4 class="card-title">Data Table</h4> -->
                    <div class="row mt-4">
                        <!-- <div class="form-group row">
                    <div class="col-sm-6">
                        <b-button variant="success" v-b-modal.modal-tambah
                        ><i class="fas fa-plus"></i> Tambah</b-button
                        >
                    </div>
                    <div class="col-sm-6 text-end">
                    </div>
                    </div> -->
                    <div class="col-sm-6 col-md-6">
                        <b-button variant="success" v-if="dataProyek.ability.tambah_anggota == true" size="sm" class="mb-2" v-b-modal.modal-tambah-anggota
                        ><i class="fas fa-plus"></i> Add Member</b-button
                        >
                    </div>
                    <!-- <div class="col-sm-6 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                            Show&nbsp;
                            <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                        </label>
                        </div>
                    </div> -->
                    <!-- Search -->
                    <div class="col-sm-6 col-md-6">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                        <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                            v-model="filterAnggota"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ml-2"
                            ></b-form-input>
                        </label>
                        </div>
                    </div>
                    <!-- End search -->
                    </div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                        <b-table
                            :items="dataAnggota"
                            :fields="fieldsAnggota"
                            responsive="sm"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :filter="filterAnggota"
                            :filter-included-fields="filterOn"
                            @filtered="onFiltered"
                            head-variant="light"
                        >
                        <template #cell(ability)="data">
                            <ul style="list-style-type:none;">
                                <li v-for="(value, propertyName, index) in data.item.ability" :key="index">
                                    
                                    <b-form-checkbox disabled v-if="value == true" v-model="checked">
                                        {{ propertyName }}
                                    </b-form-checkbox>
                                    <b-form-checkbox disabled v-else >
                                        {{ propertyName }} 
                                    </b-form-checkbox>
                                </li>
                            </ul>
                        </template>
                        <template v-slot:cell(timeline)="data">
                            <div class="progress position-relative" style="height: 2rem;background-color: #000;">
                                <div class="progress-bar bg-success" role="progressbar" style="width: 60%"
                                    aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                <span class="justify-content-center w-100 mt-3 text-white"><strong>{{ data.item.progres}}</strong></span>
                            </div>
                        </template>
                        <template #cell(action)="data">
                            <a type="button" v-if="dataProyek.ability.ubah_anggota == true" @click="getDetailAnggota(data.item.id)" data-toggle="tooltip" title="Edit" class="mr-3 text-primary"><i class="mdi mdi-pencil font-size-18"></i></a>
                            <a type="button" v-if="dataProyek.ability.hapus_anggota == true"  @click="deleteAnggota(data.item.id)" title="Delete" class="text-danger"><i class="mdi mdi-trash-can font-size-18"></i></a>
                        </template>
                    </b-table>
                    </div>
                    <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                            <label class="d-inline-flex align-items-center">
                            Show&nbsp;
                            <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                            </label>
                        </div>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        
      <!-- modal tambah anggota -->
      <b-modal
        body-class="bg-white"
        id="modal-tambah-anggota"
        title="Add Member"
        cancel-title="Close"
        ok-title="Accept"
        cancel-variant="outline-secondary"
        size="md"
        @show="resetModal"
        @hidden="resetModal"
      >
        <form
          ref="form"
          @submit.stop.prevent="onSubmit"
          data-vv-scope="form-2"
        >
          <b-row>
            <b-col md="12">
                <b-form-group label="Name : *">
                <v-select
                    v-model="detailAnggota.id_user"
                    :options="dataUser"
                    :reduce="user => user.id"
                    v-validate="'required'" name="Nama"
                    :class="{ 'is-invalid': errors.has('form-2.Nama') }"
                    label="nama"
                />
                <span class="text-danger" v-if="errors.has('form-2.Nama')">
                    {{ errors.first('form-2.Nama') }}
                </span>
                </b-form-group>
            </b-col>
            <b-col md="12">
                <b-form-group label="Position : *">
                <v-select
                    v-model="detailAnggota.id_posisi"
                    :options="dataPosisi"
                    :reduce="user => user.id"
                    v-validate="'required'" name="Nama"
                    :class="{ 'is-invalid': errors.has('form-2.Posisi') }"
                    label="nama_posisi"
                />
                <span class="text-danger" v-if="errors.has('form-2.Posisi')">
                    {{ errors.first('form-2.Posisi') }}
                </span>
                </b-form-group>
            </b-col>
          </b-row>
        </form>
      

        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              
              style="float:right"
              @click="tambahAnggota"
            >
            <i
              class="fas fa-save"/>
            
              Save
            </b-button>
          </div>
        </template>
      </b-modal>

      <!-- modal edit anggota -->
      <b-modal
        body-class="bg-white"
        id="modal-edit-anggota"
        title="Edit Anggota"
        cancel-title="Close"
        ok-title="Accept"
        cancel-variant="outline-secondary"
        size="md"
        @hidden="resetModal"
      >
        <form
          ref="form"
          @submit.stop.prevent="onSubmit"
          data-vv-scope="form-2"
        >
          <b-row>
            <b-col md="12">
                <b-form-group label="Name : *">
                <v-select
                    v-model="detailAnggota.id_user"
                    :options="dataUser"
                    :reduce="user => user.id"
                    v-validate="'required'" name="Nama"
                    :class="{ 'is-invalid': errors.has('form-2.Nama') }"
                    label="nama"
                />
                <span class="text-danger" v-if="errors.has('form-2.Nama')">
                    {{ errors.first('form-2.Nama') }}
                </span>
                </b-form-group>
            </b-col>
            <b-col md="12">
                <b-form-group label="Position : *">
                <v-select
                    v-model="detailAnggota.id_posisi"
                    :options="dataPosisi"
                    :reduce="user => user.id"
                    v-validate="'required'" name="Nama"
                    :class="{ 'is-invalid': errors.has('form-2.Posisi') }"
                    label="nama_posisi"
                />
                <span class="text-danger" v-if="errors.has('form-2.Posisi')">
                    {{ errors.first('form-2.Posisi') }}
                </span>
                </b-form-group>
            </b-col>
          </b-row>
        </form>
      

        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              
              style="float:right"
              @click="editAnggota"
            >
            <i
              class="fas fa-save"/>
            
              Save
            </b-button>
          </div>
        </template>
      </b-modal>
        
    </div>
</template>
<script>
import axios from 'axios'
import vSelect from 'vue-select'
import Swal from "sweetalert2";
export default {
    components: { vSelect },
    data() {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filterAnggota: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fieldsAnggota: [
                { key: "nama_user", label: "Name", sortable: true },
                { key: "nama_posisi", label: "Position", sortable: true },
                { key: "ability", label: "Ability", sortable: true },
                'action'
            ],
            baseapi: localStorage.getItem("baseapi"),
            user: JSON.parse(localStorage.getItem('user')),
            dataAbility: [],
            dataVariable:[],
            idProyek: localStorage.getItem("idProyek"),
            detailPosisi: [],
            checked: true,
            detailAnggota: [],
            dataAnggota: [],
            dataUser:[],
            dataProyek: JSON.parse(localStorage.getItem('proyek')),
        }
    },
    props: {
        dataPosisi: {
            type: Array,
                default: () => {
                    return [];
                },
        },
    },
    computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataAnggota.length;
    }
  },
  mounted() {
    this.loaddata()
    this.getVariable()
    this.getAbility()
    this.getUser()
    this.getPosisi()
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    // data posisi
    getPosisi(){
        axios.get(this.baseapi+"api/posisi?filter=id_proyek,=,"+this.idProyek, {
            headers: {
                Authorization: `Bearer `+this.user.accessToken
            }
        }).then(response => {
            this.dataPosisi = response.data.data;
        }).catch(error=>{
            console.log(error)
            return error
            });
    },

    // tambah anggtoa
    tambahAnggota() {
        this.$validator.validateAll('form-1').then((result) => {
            if (result) {
            axios.post(this.baseapi+'api/assign_member', {
                id_user: this.detailAnggota.id_user,
                id_proyek: this.idProyek,
                id_posisi: this.detailAnggota.id_posisi,
            },{
            headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer `+this.user.accessToken
                }
                })
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil Ditambah',
                    showConfirmButton: false,
                    timer: 1500
                })
                this.loaddata()
                this.detailAnggota= []

                // Wait until the models are updated in the UI
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-tambah-anggota')
                });
            return res;
            })
            .catch((err) => {
            console.log(err)
            Swal.fire({
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 1500
            })
            return err;
        });
    }
          })
    },

    // dataUser
    getUser(){
        axios.get(this.baseapi+"api/user?filter=role,=,user", {
            headers: {
                Authorization: `Bearer `+this.user.accessToken
            }
        }).then(response => {
            this.dataUser = response.data.data;
        }).catch(error=>{
            console.log(error)
            return error
            });
    },

    resetModal(){
      this.detailPosisi = []
    },

    deleteAnggota(id){
        Swal.fire({
          title: 'Are you sure to delete this member?',
          // text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.isConfirmed) {
            axios
                .delete(
                    this.baseapi+`api/assign_member/`+id,
                    {
                        headers: {
                            Authorization: `Bearer `+this.user.accessToken
                        }
                    }
                    )
                    .then((res) => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Berhasil Dihapus',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    this.loaddata()
                    return res;
                    })
                    .catch((err) => {
                    console.log(err)
                    Swal.fire({
                        icon: 'error',
                        title: err.response.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    return err;
                });
          }
        })
    },
    

    editAnggota() {
        this.$validator.validateAll();
        axios
            .put(
            this.baseapi+`api/assign_member/`+this.detailAnggota.id,
            {
                id_user: this.detailAnggota.id_user,
                id_proyek: this.idProyek,
                id_posisi: this.detailAnggota.id_posisi,
            }
            ,{
                headers:{
                    Authorization: `Bearer `+this.user.accessToken
                }
            })
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil Diubah',
                    showConfirmButton: false,
                    timer: 1500
                })
                this.detailAnggota = []
                this.loaddata()

                this.$nextTick(() => {
                    this.$bvModal.hide('modal-edit-anggota')
                })
            return res;
            })
            .catch((err) => {
            console.log(err)
            Swal.fire({
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 1500
            })
            return err;
        });
    },
    
    // loaddata user
    loaddata(){
        axios.get(this.baseapi+"api/assign_member?filter=id_proyek,=,"+this.idProyek, {
            headers: {
                Authorization: `Bearer `+this.user.accessToken
            }
        }).then(response => {
            this.dataAnggota = response.data.data;
            // if (data === 'anggota') {
            //     this.$bvModal.show('modal-anggota')
            // } else {
            //     this.detailTask = []
            //     this.$bvModal.show('modal-tambah')
            // }
        }).catch(error=>{
            console.log(error)
            return error
            });
    },

    // detail
    getDetailAnggota(id){
        axios.get(this.baseapi+"api/assign_member/"+id, {
            headers: {
                Authorization: `Bearer `+this.user.accessToken
            }
        }).then(response => {
            this.detailAnggota = response.data.data;
            this.$nextTick(() => {
                this.$bvModal.show('modal-edit-anggota')
            });
        }).catch(error=>{
            console.log(error)
            return error
            });
    },

    getAbility(){
        axios.get(this.baseapi+"api/ability", {
            headers: {
                Authorization: `Bearer `+this.user.accessToken
            }
        }).then(response => {
            this.dataAbility = response.data.data;
        }).catch(error=>{
            console.log(error)
            return error
            });
    },

    
    getVariable(){
        axios.get(this.baseapi+"api/variable_group", {
            headers: {
                Authorization: `Bearer `+this.user.accessToken
            }
        }).then(response => {
            this.dataVariable = response.data.data;
        }).catch(error=>{
            console.log(error)
            return error
            });
    },

    // detail
    getDetail(id){
        axios.get(this.baseapi+"api/variable/"+id, {
            headers: {
                Authorization: `Bearer `+this.user.accessToken
            }
        }).then(response => {
            this.dataVariables = response.data.data;
            this.$nextTick(() => {
                this.$bvModal.show('modal-edit-variable')
            });
        }).catch(error=>{
            console.log(error)
            return error
            });
    }
  }
}
</script>