<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <!-- <h4 class="card-title">Data Table</h4> -->
                        <div class="row mt-4">
                            <!-- <div class="form-group row">
                    <div class="col-sm-6">
                        <b-button variant="success" v-b-modal.modal-tambah
                        ><i class="fas fa-plus"></i> Tambah</b-button
                        >
                    </div>
                    <div class="col-sm-6 text-end">
                    </div>
                    </div> -->
                            <div class="col-sm-6 col-md-6">
                                <b-button variant="success" v-if="dataProyek.ability.buat_posisi == true" size="sm"
                                    class="mb-2" v-b-modal.modal-tambah-posisi><i class="fas fa-plus"></i> Add
                                    Position</b-button>
                            </div>
                            <!-- <div class="col-sm-6 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                            Show&nbsp;
                            <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                        </label>
                        </div>
                    </div> -->
                            <!-- Search -->
                            <div class="col-sm-6 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                                            class="form-control form-control-sm ml-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table :items="dataPosisi" :fields="fieldsPosisi" responsive="sm" :per-page="perPage"
                                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
                                head-variant="light">
                                <template #cell(ability)="data">
                                    
                                    <ul style="list-style-type:none;">
                                        <li v-for="(value, propertyName, index) in data.item.ability" :key="index">

                                            <b-form-checkbox disabled v-if="value == true" v-model="checked">
                                                {{ propertyName }}
                                            </b-form-checkbox>
                                            <b-form-checkbox disabled v-else>
                                                {{ propertyName }}
                                            </b-form-checkbox>
                                        </li>
                                    </ul>
                                </template>
                                <template #cell(variable_tampil)="data">
                                    <ul style="list-style-type:none;">
                                        <li v-for="(value, propertyName, index) in data.item.variable_tampil" :key="index">

                                            <b-form-checkbox disabled v-if="value == true" v-model="checked">
                                                {{ propertyName }}
                                            </b-form-checkbox>
                                            <b-form-checkbox disabled v-else>
                                                {{ propertyName }}
                                            </b-form-checkbox>
                                        </li>
                                    </ul>
                                </template>
                                <template #cell(parent)="data">
                                    <ul>
                                        <li v-for="(row, index) in data.item.parent" :key="index">
                                            {{ row }}
                                        </li>
                                    </ul>
                                </template>
                                <template v-slot:cell(timeline)="data">
                                    <div class="progress position-relative" style="height: 2rem;background-color: #000;">
                                        <div class="progress-bar bg-success" role="progressbar" style="width: 60%"
                                            aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                        <span class="justify-content-center w-100 mt-3 text-white"><strong>{{
                                            data.item.progres }}</strong></span>
                                    </div>
                                </template>
                                <template #cell(action)="data">
                                    <a type="button" v-if="dataProyek.ability.ubah_posisi == true"
                                        @click="getDetailPosisi(data.item.id)" data-toggle="tooltip" title="Edit"
                                        class="mr-3 text-primary"><i class="mdi mdi-pencil font-size-18"></i></a>
                                    <a type="button" v-if="dataProyek.ability.hapus_posisi == true"
                                        @click="deletePosisi(data.item.id)" title="Delete" class="text-danger"><i
                                            class="mdi mdi-trash-can font-size-18"></i></a>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm"
                                            :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows"
                                            :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal tambah posisi -->
        <b-modal body-class="bg-white" id="modal-tambah-posisi" title="Add Position" cancel-title="Close" ok-title="Accept"
            cancel-variant="outline-secondary" size="lg" @show="resetModal" @hidden="resetModal">
            <form ref="form" @submit.stop.prevent="onSubmit" data-vv-scope="form-1">
                <b-row>
                    <b-col md="12">
                        <b-form-group label="Name" label-for="email" class="mb-3">
                            <b-form-input type="text" name="Name" v-model="detailPosisi.nama_posisi" v-validate="'required'"
                                :class="{ 'form-control': true, 'is-invalid': errors.has('form-1.Nama') }" />
                            <span class="text-danger" v-if="errors.has('form-1.Nama')">
                                {{ errors.first('form-1.Nama') }}
                            </span>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Ability">
                            <!-- <b-row>
                                <b-col md="6" v-for="item in dataAbility" :key="item">
                                        <input type="checkbox" v-model="detailPosisi.ability" :id="item.id"
                                            :value="item.id" />
                                        <label>{{ item.text_ability }}</label>
                                    
                                </b-col>
                            </b-row>
                            {{ detailPosisi.ability }} -->
                            <b-form-checkbox-group v-model="detailPosisi.ability" :options="dataAbility"
                                :aria-describedby="ariaDescribedby" name="flavour-2a" stacked value-field="id"
                                text-field="text_ability"></b-form-checkbox-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Data Views" label-for="email" class="mb-3">
                            <v-select multiple :reduce="user => user.id" label="nama_group"
                                v-model="detailPosisi.variable_group_tampil" :options="dataVariable" />
                            <span class="text-danger" v-if="errors.has('durasi')">
                                {{ errors.first('durasi') }}
                            </span>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Parent" label-for="email" class="mb-3">
                            <v-select multiple :reduce="user => user.id" label="nama_posisi" v-model="detailPosisi.parent"
                                :options="dataPosisi" />
                            <span class="text-danger" v-if="errors.has('durasi')">
                                {{ errors.first('durasi') }}
                            </span>
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>


            <template #modal-footer>
                <div class="w-100">
                    <b-button variant="primary" style="float:right" @click="tambahPosisi">
                        <i class="fas fa-save" />

                        Save
                    </b-button>
                </div>
            </template>
        </b-modal>

        <!-- modal edit posisi -->
        <b-modal body-class="bg-white" id="modal-edit-posisi" title="Edit Posisi" cancel-title="Close" ok-title="Accept"
            cancel-variant="outline-secondary" size="lg" @hidden="resetModal">
            <form ref="form" data-vv-scope="form-1" @submit.stop.prevent="onSubmit">
                <b-row>
                    <b-col md="12">
                        <b-form-group label="Name" label-for="email" class="mb-3">
                            <b-form-input type="text" name="durasi" v-model="detailPosisi.nama_posisi"
                                v-validate="'required'"
                                :class="{ 'form-control': true, 'is-invalid': errors.has('form-1.Nama') }" />
                            <span class="text-danger" v-if="errors.has('form-1.Nama')">
                                {{ errors.first('form-1.Nama') }}
                            </span>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Ability" label-for="email" class="mb-3">
                            <b-form-checkbox-group v-model="detailPosisi.ability" :options="dataAbility"
                                :aria-describedby="ariaDescribedby" name="flavour-2a" stacked value-field="id"
                                text-field="text_ability"></b-form-checkbox-group>

                        <!-- <v-select multiple :reduce="user => user.id" label="text_ability" v-model="detailPosisi.ability"
                                :options="dataAbility" />
                            <span class="text-danger" v-if="errors.has('durasi')">
                                {{ errors.first('durasi') }}
                            </span> -->
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Data Views" label-for="email" class="mb-3">
                            <v-select multiple :reduce="dataVariable => dataVariable.id" label="nama_group"
                                v-model="detailPosisi.variable_group_tampil" :options="dataVariable" />
                            <span class="text-danger" v-if="errors.has('durasi')">
                                {{ errors.first('durasi') }}
                            </span>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Parent" label-for="email" class="mb-3">
                            <v-select multiple :reduce="user => user.id" label="nama_posisi" v-model="detailPosisi.parent"
                                :options="dataPosisi" />
                            <span class="text-danger" v-if="errors.has('durasi')">
                                {{ errors.first('durasi') }}
                            </span>
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>


            <template #modal-footer>
                <div class="w-100">
                    <b-button variant="primary" style="float:right" @click="editPosisi">
                        <i class="fas fa-save" />

                        Save
                    </b-button>
                </div>
            </template>
        </b-modal>

    </div>
</template>
<script>
import axios from 'axios'
import vSelect from 'vue-select'
import Swal from "sweetalert2";
import VueLoading from 'vuejs-loading-plugin'
import Vue from 'vue';

// using default options
Vue.use(VueLoading)

// overwrite defaults
Vue.use(VueLoading, {
    dark: true, // default false
    text: 'Loading', // default 'Loading'
    loading: true, // default false
    //customLoader: myVueComponent, // replaces the spinner and text with your own
    background: 'rgb(255,255,255)', // set custom background
    classes: ['myclass'] // array, object or string
})
export default {
    components: { vSelect },
    data() {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fieldsPosisi: [
                { key: "nama_posisi", label: "Name", sortable: true },
                { key: "ability", label: "Ability", sortable: true },
                { key: "variable_tampil", label: "Data Views", sortable: true },
                { key: "parent", label: "Parent", sortable: true },
                'action'
            ],
            baseapi: localStorage.getItem("baseapi"),
            user: JSON.parse(localStorage.getItem('user')),
            dataAbility: [],
            dataVariable: [],
            idProyek: localStorage.getItem("idProyek"),
            detailPosisi: [],
            checked: true,
            dataProyek: JSON.parse(localStorage.getItem('proyek')),
        }
    },
    props: {
        dataPosisi: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.dataPosisi.length;
        }
    },
    mounted() {
        // this.loaddata()
        this.getVariable()
        this.getAbility()
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */
        addTag(newTag) {
            console.log(newTag)
            const tag = {
                text_ability: newTag,
                id: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.dataAbility.push(tag)
            this.detailPosisi.ability.push(tag)
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.detailPosisi = []
        },

        deleteData(id) {
            axios
                .delete(
                    this.baseapi + `api/variable/` + id,
                    {
                        headers: {
                            Authorization: `Bearer ` + this.user.accessToken
                        }
                    }
                )
                .then((res) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Berhasil Dihapus',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.$emit('loaddata')
                    return res;
                })
                .catch((err) => {
                    console.log(err)
                    Swal.fire({
                        icon: 'error',
                        title: err.response.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    return err;
                });
        },

        // tambah posisi
        tambahPosisi() {
            this.$validator.validateAll('form-1').then((result) => {
                if (result) {
                    this.$loading(true);
                    axios.post(this.baseapi + 'api/posisi', {
                        ability: this.detailPosisi.ability,
                        id_proyek: this.idProyek,
                        variable_group_tampil: this.detailPosisi.variable_group_tampil,
                        parent: this.detailPosisi.parent,
                        nama_posisi: this.detailPosisi.nama_posisi
                    }, {
                        headers: {
                            "Content-type": "application/json",
                            Authorization: `Bearer ` + this.user.accessToken
                        }
                    })
                        .then((res) => {
                            this.$loading(false);
                            Swal.fire({
                                icon: 'success',
                                title: 'Berhasil Ditambah',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.$emit('loaddata')
                            this.detailPosisi = []

                            // Wait until the models are updated in the UI
                            this.$nextTick(() => {
                                this.$bvModal.hide('modal-tambah-posisi')
                            });
                            return res;
                        })
                        .catch((err) => {
                            this.$loading(false);
                            console.log(err)
                            Swal.fire({
                                icon: 'error',
                                title: err.response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                            return err;
                        });
                }
            })
        },

        editPosisi() {
            this.$validator.validateAll('form-1').then((result) => {
                if (result) {
                    this.$loading(true);
                    axios.put(this.baseapi + 'api/posisi/' + this.detailPosisi.id, {
                        nama_posisi: this.detailPosisi.nama_posisi,
                        ability: this.detailPosisi.ability,
                        id_proyek: this.idProyek,
                        variable_group_tampil: this.detailPosisi.variable_group_tampil,
                        parent: this.detailPosisi.parent,
                    }, {
                        headers: {
                            "Content-type": "application/json",
                            Authorization: `Bearer ` + this.user.accessToken
                        }
                    })
                        .then((res) => {
                            this.$loading(false);
                            Swal.fire({
                                icon: 'success',
                                title: 'Berhasil Ditambah',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.$emit('loaddata')
                            this.detailPosisi = []

                            // Wait until the models are updated in the UI
                            this.$nextTick(() => {
                                this.$bvModal.hide('modal-edit-posisi')
                            });
                            return res;
                        })
                        .catch((err) => {
                            this.$loading(false);
                            console.log(err)
                            Swal.fire({
                                icon: 'error',
                                title: err.response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                            return err;
                        });
                }
            })
        },

        deletePosisi(id) {
            Swal.fire({
                title: 'Are you sure to delete this position?',
                // text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .delete(
                            this.baseapi + `api/posisi/` + id,
                            {
                                headers: {
                                    Authorization: `Bearer ` + this.user.accessToken
                                }
                            }
                        )
                        .then((res) => {
                            Swal.fire({
                                icon: 'success',
                                title: 'Berhasil Dihapus',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.$emit('loaddata')
                            this.getVariable()
                            this.getAbility()
                            this.loaddata()
                            return res;
                        })
                        .catch((err) => {
                            console.log(err)
                            Swal.fire({
                                icon: 'error',
                                title: err.response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                            return err;
                        });
                }
            })
        },

        // loaddata user
        loaddata() {
            axios.get(this.baseapi + "api/posisi?filter=id_proyek,=," + this.idProyek, {
                headers: {
                    Authorization: `Bearer ` + this.user.accessToken
                }
            }).then(response => {
                this.dataPosisi = response.data.data;
            }).catch(error => {
                console.log(error)
                return error
            });
        },

        getDetailPosisi(id) {
            axios.get(this.baseapi + "api/posisi/" + id, {
                headers: {
                    Authorization: `Bearer ` + this.user.accessToken
                }
            }).then(response => {
                this.detailPosisi = response.data.data;
                if (this.detailPosisi.ability != null) {
                    const myArray = this.detailPosisi.ability.split("||").map(Number);
                    this.detailPosisi.ability = myArray
                    console.log(this.detailPosisi.ability)
                }

                if (this.detailPosisi.variable_group_tampil != null) {
                    const myArray = this.detailPosisi.variable_group_tampil.split("||").map(Number);
                    this.detailPosisi.variable_group_tampil = myArray
                    console.log(this.detailPosisi.variable_group_tampil)
                }

                if (this.detailPosisi.parent != null) {
                    const myArray = this.detailPosisi.parent.split("||").map(Number);
                    this.detailPosisi.parent = myArray
                    console.log(this.detailPosisi.parent)
                }
                this.$bvModal.show('modal-edit-posisi')
            }).catch(error => {
                console.log(error)
                return error
            });
        },

        getAbility() {
            axios.get(this.baseapi + "api/ability", {
                headers: {
                    Authorization: `Bearer ` + this.user.accessToken
                }
            }).then(response => {
                this.dataAbility = response.data.data;
            }).catch(error => {
                console.log(error)
                return error
            });
        },


        getVariable() {
            axios.get(this.baseapi + "api/variable_group", {
                headers: {
                    Authorization: `Bearer ` + this.user.accessToken
                }
            }).then(response => {
                this.dataVariable = response.data.data;
            }).catch(error => {
                console.log(error)
                return error
            });
        },

        // detail
        getDetail(id) {
            axios.get(this.baseapi + "api/variable/" + id, {
                headers: {
                    Authorization: `Bearer ` + this.user.accessToken
                }
            }).then(response => {
                this.dataVariables = response.data.data;
                this.$nextTick(() => {
                    this.$bvModal.show('modal-edit-variable')
                });
            }).catch(error => {
                console.log(error)
                return error
            });
        }
    }
}
</script>