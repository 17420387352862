<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import dataPosisi from "./posisi/index"
import dataAnggota from "./anggota/index"
import axios from 'axios'
// import Swal from "sweetalert2";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Member",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, dataPosisi, dataAnggota },
  data() {
    return {
    //   tableData: tableData,
      title: "Member Data Management",
      items: [
        {
          text: "Project",
          href: "/"
        },
        {
          text: "Members",
          active: true
        }
      ],
      baseapi: localStorage.getItem("baseapi"),
      idProyek: localStorage.getItem("idProyek"),
      dataProyek: JSON.parse(localStorage.getItem('proyek')),
      user: JSON.parse(localStorage.getItem('user')),
      dataTask: [],
      dataPosisi: []
    };
  },
  methods: {
    loaddata(){
        axios.get(this.baseapi+"api/posisi?filter=id_proyek,=,"+this.idProyek, {
            headers: {
                Authorization: `Bearer `+this.user.accessToken
            }
        }).then(response => {
            this.dataPosisi = response.data.data;
        }).catch(error=>{
            console.log(error)
            return error
            });
    },
  },
  mounted(){
    this.loaddata()
  },
  computed: {
    /**
     * Total no. of records
     */
   
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
            <div class="card-body">
                <b-tabs class="mb-lg">
                    <b-tab title="Position" active>
                        <dataPosisi :dataPosisi="dataPosisi" @loaddata="loaddata"/>
                    </b-tab>
                    <b-tab title="Members" >
                        <dataAnggota :dataPosisi="dataPosisi" @loaddata="loaddata"/>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
      </div>
    </div>
  </Layout>
</template>